import actions from './actions';

const initialState = {
  loading: false,
  message: '',
  Webhooks: [],
  result: [],
};

const WebhookReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_WEBHOOK_LOCAL_SUC:
      return {
        ...state,
        result: state.Webhook.filter(
          (v) =>
            v.name && v.name.toLowerCase().includes(action.data.toLowerCase()),
        ),
      };
    case actions.GET_WEBHOOK_REQ:
      return {
        ...state,
        loading: true,
        getWebhook: {
          success: false,
          error: false,
        },
      };
    case actions.GET_WEBHOOK_SUC:
      return {
        ...state,
        loading: false,
        getWebhook: {
          success: true,
          error: false,
          data: action.payload,
        },
        Webhooks: [...action.webhooks],
      };
    case actions.GET_WEBHOOK_FAIL:
      return {
        ...state,
        loading: false,
        getWebhook: {
          success: false,
          error: true,
        },
        message: action.payload,
      };
    case actions.DLT_WEBHOOK_REQ:
      return {
        ...state,
        deleteWebhook: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case actions.DLT_WEBHOOK_SUC:
      return {
        ...state,
        deleteWebhook: {
          loading: false,
          success: true,
          error: false,
        },
        Webhooks: state.Webhooks.filter((brand) => brand.id !== action.id),
      };
    case actions.DLT_WEBHOOK_FAIL:
      return {
        ...state,
        deleteWebhook: {
          loading: false,
          success: false,
          error: true,
        },
        message: actions.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default WebhookReducer;
