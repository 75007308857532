import React from 'react';
import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import {
  postDealApi,
  getDealsApi,
  deleteDealApi,
  editDealApi,
  getDealApi,
} from '../../../../api/fetchDeals';
import { push } from 'react-router-redux';
import ShowMessage from '../../../../components/Toast/Toast';
import actions from './actions';
import { history } from '../../../../utils/index';

function* callFetchDealReq(action) {
  try {
    let apiResponse = yield call(getDealsApi, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: actions.GET_DEALS_SUC,
      statusCode: status,
      deals: data?.deals,
    });
  } catch (err) {
    // const {
    //   err: {
    //     data: { error },
    //   },
    // } = err;

    yield put({
      type: actions.GET_DEALS_FAIL,
      payload: err,
    });

    // yield ShowMessage(error.statusCode, error.message);
  }
}

function* callSendDealReq(action) {
  try {
    let apiResponse = yield call(postDealApi, action.payload);
    console.log(apiResponse);

    let { data, status } = apiResponse;

    yield put({
      type: actions.SEND_DEALS_SUC,
      payload: data,
    });

    history.push('/dashboard/deals');
    history.go(0);
    yield ShowMessage(status, 'Deals created successfully.');
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;

    yield put({
      type: actions.SEND_DEALS_FAIL,
      payload: error,
    });

    yield ShowMessage(error.statusCode, error.message);
  }
}

function* callEditDealReq(action) {
  try {
    let apiResponse = yield call(editDealApi, action.id, action.payload);
    console.log(apiResponse);

    let { data, status } = apiResponse;

    yield put({
      type: actions.EDIT_DEALS_SUC,
      payload: data,
    });

    history.push('/dashboard/deals');
    history.go(0);
    yield ShowMessage(status, 'Deals updated successfully.');
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;

    yield put({
      type: actions.EDIT_DEALS_FAIL,
      payload: error,
    });

    yield ShowMessage(error.statusCode, error.message);
  }
}

function* callDeleteDealReq(action) {
  try {
    let apiResponse = yield call(deleteDealApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_DEALS_SUC,
      statusCode: status,
      id: action.payload,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_DEALS_FAIL,
        payload: err?.reponse?.message || 'Error',
      });
    }
    yield put({
      type: actions.DLT_DEALS_FAIL,
      payload: err?.message || "Error",
    });
  }
}

export function* sendDeal() {
  yield takeEvery(actions.SEND_DEALS_REQ, callSendDealReq);
}

export function* editDeal() {
  yield takeEvery(actions.EDIT_DEALS_REQ, callEditDealReq);
}

export function* getDeal() {
  yield takeEvery(actions.GET_DEALS_REQ, callFetchDealReq);
}

export function* dltDeal() {
  yield takeEvery(actions.DLT_DEALS_REQ, callDeleteDealReq);
}

export default function* () {
  return yield all([
    fork(sendDeal),
    fork(getDeal),
    fork(dltDeal),
    fork(editDeal),
  ]);
}
