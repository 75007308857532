const entities = '[WEBHOOK]';

const action = {
  GET_WEBHOOK_REQ: `${entities} GET_WEBHOOK_REQ`,
  GET_WEBHOOK_SUC: `${entities} GET_WEBHOOK_SUC`,
  GET_WEBHOOK_FAIL: `${entities} GET_WEBHOOK_FAIL`,

  DLT_WEBHOOK_REQ: `${entities} DLT_WEBHOOK_REQ`,
  DLT_WEBHOOK_SUC: `${entities} DLT_WEBHOOK_SUC`,
  DLT_WEBHOOK_FAIL: `${entities} DLT_WEBHOOK_FAIL`,

  SEARCH_WEBHOOK_LOCAL_REQ: `${entities} SEARCH_WEBHOOK_LOCAL_REQ`,
  SEARCH_WEBHOOK_LOCAL_SUC: `${entities} SEARCH_WEBHOOK_LOCAL_SUC`,
  SEARCH_WEBHOOK_LOCAL_FAIL: `${entities} SEARCH_WEBHOOK_LOCAL_FAIL`,

  getWebhookReq: (payload) => ({
    type: action.GET_WEBHOOK_REQ,
    payload,
  }),

  deleteWebhookReq: (payload) => ({
    type: action.DLT_WEBHOOK_REQ,
    payload,
  }),

  searchWebhookReq: (data) => ({
    type: action.SEARCH_WEBHOOK_LOCAL_REQ,
    data,
  }),
};

export default action;
