import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'utils';
import ShowMessage from '../../../components/Toast/Toast';
import { delay } from '../../../helper/utility';
import actions from './actions';
import { deleteWebhookApi, getWebhookLogsApi } from 'api/webhook';

function* callFetchWebhookReq(action) {
  try {
    let apiResponse = yield call(getWebhookLogsApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.GET_WEBHOOK_SUC,
      statusCode: status,
      webhooks: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_WEBHOOK_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_WEBHOOK_FAIL,
      payload: err.message,
    });
  }
}
function* callDeleteWebhookReq(action) {
  try {
    let apiResponse = yield call(deleteWebhookApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_WEBHOOK_SUC,
      statusCode: status,
      id: action.payload,
    });
    delay(1000);
    yield ShowMessage(status, 'webhook deleted successfully.');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_WEBHOOK_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.DLT_WEBHOOK_FAIL,
      payload: err.message,
    });
  }
}

function* callSearchLocalWebhookRequest(action) {
  try {
    yield delay(400);
    yield put({
      type: actions.SEARCH_WEBHOOK_LOCAL_SUC,
      data: action.data,
    });
  } catch (error) {
    yield put({
      type: actions.SEARCH_WEBHOOK_LOCAL_FAIL,
      data: 'Error occured while searching webhooks',
    });
    yield ShowMessage(400, 'Error occured while searching webhooks');
  }
}

export function* fetchWebhooks() {
  yield takeEvery(actions.GET_WEBHOOK_REQ, callFetchWebhookReq);
}

export function* deleteWebhook() {
  yield takeEvery(actions.DLT_WEBHOOK_REQ, callDeleteWebhookReq);
}

export function* searchLocalWebhook() {
  yield takeEvery(
    actions.SEARCH_WEBHOOK_LOCAL_REQ,
    callSearchLocalWebhookRequest,
  );
}

export default function* webhookSaga() {
  return yield all([
    fork(fetchWebhooks),
    fork(deleteWebhook),
    fork(searchLocalWebhook),
  ]);
}
