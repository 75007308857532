import { APP_CONFIG } from "app/config";

const hostname = window.location.hostname;

const isLocalHost = () => {
  return !!(
    hostname.indexOf('localhost') > -1 ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

const containsHost = () => {};

export default {
  DEFAULT_TENANT: -2,
  baseURL: APP_CONFIG.API_BASE_URL || 'https://api.salesberry.com.np',
  assetURL: 'https://api.salesberry.com.np/',
  assetURLcategory: 'https://api.salesberry.com.np/asset/upload/',
  httpAssetURL: APP_CONFIG.FILE_URL,
  appname: APP_CONFIG.APP_NAME,
  mail: APP_CONFIG.MAIL,
  website: APP_CONFIG.WEBSITE,
  amazonUrl: APP_CONFIG.AMAZON_URL,
};
