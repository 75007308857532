import actions from './actions';

const initialState = {
  loading: false,
  success: false,
  error: false,
  data: [],
};

const initialReducers = {
  user: initialState,
  vendor: initialState,
  category: initialState,
  brand: initialState,
  deal: initialState,
  coupon: initialState,
  order: initialState,
  shop: initialState,
  banner: initialState,
  MJH : null,
  STA : null,
  STD : null,
};

const StatisticsReducer = (state = initialReducers, action) => {
  switch (action.type) {
    case actions.COUNT_USER:
      return {
        ...state,
        user: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_USER_SUC:
      return {
        ...state,
        user: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_USER_FAIL:
      return {
        ...state,
        user: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_VENDOR:
      return {
        ...state,
        vendor: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_VENDOR_SUC:
      return {
        ...state,
        vendor: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_VENDOR_FAIL:
      return {
        ...state,
        vendor: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_CATEGORY:
      return {
        ...state,
        category: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_CATEGORY_SUC:
      return {
        ...state,
        category: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_CATEGORY_FAIL:
      return {
        ...state,
        category: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_BRAND:
      return {
        ...state,
        brand: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_BRAND_SUC:
      return {
        ...state,
        brand: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_BRAND_FAIL:
      return {
        ...state,
        brand: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_DEAL:
      return {
        ...state,
        deal: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_DEAL_SUC:
      return {
        ...state,
        deal: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_DEAL_FAIL:
      return {
        ...state,
        deal: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_COUPON:
      return {
        ...state,
        coupon: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_COUPON_SUC:
      return {
        ...state,
        coupon: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_COUPON_FAIL:
      return {
        ...state,
        coupon: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_ORDER:
      return {
        ...state,
        order: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_ORDER_SUC:
      return {
        ...state,
        order: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_ORDER_FAIL:
      return {
        ...state,
        order: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_SHOP:
      return {
        ...state,
        shop: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_SHOP_SUC:
      return {
        ...state,
        shop: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_SHOP_FAIL:
      return {
        ...state,
        shop: {
          ...initialState,
          error: true,
        },
      };

    case actions.COUNT_BANNER:
      return {
        ...state,
        banner: {
          ...initialState,
          loading: true,
        },
      };
    case actions.COUNT_BANNER_SUC:
      return {
        ...state,
        banner: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.COUNT_BANNER_FAIL:
      return {
        ...state,
        banner: {
          ...initialState,
          error: true,
        },
      };

    ///mjh out of stock start

    case actions.LOW_STOCK_MJH:
      return {
        ...state,
        banner: {
          ...initialState,
          loading: true,
        },
      };
    case actions.LOW_STOCK_MJH_SUC:
      return {
        ...state,
        banner: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.LOW_STOCK_MJH_FAIL:
      return {
        ...state,
        banner: {
          ...initialState,
          error: true,
        },
      };

    ///mjh out of stock end

    ///STA out of stock start

    case actions.LOW_STOCK_STA:
      return {
        ...state,
        banner: {
          ...initialState,
          loading: true,
        },
      };
    case actions.LOW_STOCK_STA_SUC:
      return {
        ...state,
        banner: {
          ...initialState,
          success: true,
          data: action.payload,
        },
      };
    case actions.LOW_STOCK_STA_FAIL:
      return {
        ...state,
        banner: {
          ...initialState,
          error: true,
        },
      };

    ///mjh out of stock end

    ///STD out of stock start

    case actions.LOW_STOCK_STD:
      return {
        ...state,
      
      };
    case actions.LOW_STOCK_STD_SUC:
      return {
        ...state,
         MJH :null
      };
    case actions.LOW_STOCK_STD_FAIL:
      return {
        ...state,
        MJH :null

      };

    ///STD out of stock end

    default:
      return {
        ...state,
      };
  }
};

export default StatisticsReducer;
