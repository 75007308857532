import {
  Bell,
  Disc,
  Gift,
  HardDrive,
  HelpCircle,
  Home,
  List,
  Package,
  Paperclip,
  Plus,
  Star,
  Truck,
  User,
  Mail,
  Settings,
  MessageCircle,
} from 'react-feather';

import { FaStoreAlt, FaStore, FaImages, FaUsers } from 'react-icons/fa';
import { FcAcceptDatabase } from 'react-icons/fc';

import paths from 'route/paths';
export const ADMINMENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        active: true,
        path: '/dashboard',
      },
      {
        title: 'Profile',
        icon: User,
        type: 'link',
        active: false,
        path: '/dashboard/userprofile',
      },

      // {
      //   title: 'Products',
      //   icon: List,
      //   type: 'link',
      //   active: false,
      //   path: paths.allProduct,
      // },
      {
        title: 'Shippings',
        icon: Truck,
        type: 'sub',
        active: false,
        path: '/shippings/province',
        children: [
          {
            title: 'Province',
            type: 'link',
            active: false,
            path: '/shippings/province',
          },
          {
            title: 'Districts',
            type: 'link',
            active: false,
            path: '/shippings/district',
          },
          {
            title: 'Municipality',
            type: 'link',
            active: false,
            path: '/shippings/municipality',
          },
          {
            title: 'Area',
            type: 'link',
            active: false,
            path: '/shippings/area',
          },
        ],
      },
      {
        title: 'FAQs',
        icon: MessageCircle,
        type: 'link',
        active: false,
        path: '/FAQs',
      },
      {
        title: 'Products',
        icon: Package,
        type: 'sub',
        active: false,
        path: '/products',
        children: [
          {
            title: 'All Product',
            type: 'link',
            active: false,
            path: '/products',
          },
        ],
      },

      // {
      //   title: 'Dynamic Forms',
      //   icon: File,
      //   type: 'sub',
      //   active: false,
      //   path: '#',
      //   children: [
      //     {
      //       title: 'Product Form',
      //       type: 'link',
      //       active: false,
      //       path: '#',
      //       children: [
      //         {
      //           title: 'Add Form Group',
      //           icon: Plus,
      //           type: 'link',
      //           active: false,
      //           path: '/dashboard/form-group/create',
      //         },
      //         {
      //           title: 'Edit Form Group',
      //           icon: Plus,
      //           type: 'link',
      //           active: false,
      //           path: '/dashboard/form-group/edit',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'Vendor Documents',
      //       icon: Home,
      //       type: 'link',
      //       active: true,
      //       path: '#',
      //       children: [
      //         {
      //           title: 'Add a new form',
      //           icon: Home,
      //           type: 'link',
      //           active: true,
      //           path: '/dashboard/vendor-document',
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: 'Category',
        icon: List,
        type: 'sub',
        active: false,
        path: '/dashboard/categories',
        children: [
          {
            title: 'All Category',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/categories',
          },
          {
            title: 'Add Category',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/category/create',
          },
          // {
          //   title: 'Add Multiple Category',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/dashboard/category/multiple',
          // },
        ],
      },

      {
        title: 'Brands',
        icon: Disc,
        type: 'sub',
        active: false,
        path: paths.Brands,
        children: [
          {
            title: 'All Brands',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: paths.Brands,
          },
          {
            title: 'Add Brands',
            icon: Plus,
            type: 'link',
            active: false,
            path: paths.createBrand,
          },
        ],
      },
      // {
      //   title: 'Colors',
      //   icon: Wind,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/colors',
      //   children: [
      //     {
      //       title: 'All Colors',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/colors',
      //     },
      //     {
      //       title: 'Add Colors',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/color/create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Variants',
      //   icon: Package,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/variants',
      //   children: [
      //     {
      //       title: 'All Variants',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/variants',
      //     },
      //     {
      //       title: 'Add Variants',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/variant/create',
      //     },
      //   ],
      // },

      {
        title: 'Deals',
        icon: Gift,
        type: 'sub',
        active: false,
        path: paths.Deals,
        children: [
          {
            title: 'All Deals',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: paths.Deals,
          },
          {
            title: 'Add Deals',
            icon: Plus,
            type: 'link',
            active: false,
            path: paths.AddDeal,
          },
        ],
      },
      // {
      //   title: 'Roles',
      //   icon: Home,
      //   type: 'sub',
      //   active: true,
      //   path: '/dashboard/roles',
      //   children: [
      //     {
      //       title: 'All Roles',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/roles',
      //     },
      //     {
      //       title: 'Add Roles',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/role/create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Shops',
      //   icon: FaStore,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/shops',
      //   children: [
      //     // {
      //     //   title: 'All Shops',
      //     //   icon: HardDrive,
      //     //   type: 'link',
      //     //   active: false,
      //     //   path: '/dashboard/shops',
      //     // },
      //     {
      //       title: 'Add Shops',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/shop/create',
      //     },
      //   ],
      // },
      {
        title: 'Banners',
        icon: FaImages,
        type: 'sub',
        active: false,
        children: [
          {
            title: 'All Banners',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/banners/all',
          },
          {
            title: 'Add Banner',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/banners/add',
          },
        ],
      },

      {
        title: 'Placeholders',
        icon: Package,
        type: 'sub',
        active: false,
        children: [
          {
            title: 'All PlaceHolders',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/placeholders',
          },
          {
            title: 'Add PlaceHolder',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/placeholder/create',
          },
        ],
      },

      {
        title: 'Site Nav',
        icon: Package,
        type: 'sub',
        active: false,
        children: [
          {
            title: 'All Site Nav',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/site-navs',
          },
          {
            title: 'Add Site Nav',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/site-nav/create',
          },
        ],
      },

      {
        title: 'Category',
        icon: HelpCircle,
        title: 'Manage Orders',
        icon: Truck,
        type: 'link',
        active: false,
        path: '/dashboard/orders',
      },

      {
        title: 'Vendors',
        icon: FaStoreAlt,
        type: 'sub',
        active: false,
        path: '/dashboard/vendors',
        children: [
          {
            title: 'All Vendors',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/vendors',
          },
          // {
          //   title: 'Add Vendors',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/dashboard/vendor/create',
          // },
        ],
      },
      {
        title: 'Stores',
        icon: FaStoreAlt,
        type: 'sub',
        active: false,
        path: '/dashboard/stores',
        children: [
          {
            title: 'All Stores',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/stores',
          },
        ]
      },
      {
        title: 'Manage Coupon',
        icon: Paperclip,
        type: 'sub',
        active: false,
        path: '/dashboard/coupons',
        children: [
          {
            title: 'All Coupons',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/coupons',
          },
          {
            title: 'Add Coupons',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/coupon/create',
          },
        ],
      },
      {
        title: 'Push Notification',
        icon: Bell,
        active: false,
        type: 'link',
        path: '/dashboard/push-notifications',
      },

      {
        title: 'Customers',
        icon: FaUsers,
        type: 'sub',
        active: false,
        path: '/dashboard/user',
        children: [
          {
            title: 'All Customers',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/users',
          },
          // {
          //   title: 'Add User',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/dashboard/user/create',
          // },
        ],
      },
      {
        title: 'Email Template',
        icon: Mail,
        type: 'link',
        active: true,
        path: '/email-templates',
      },

      // {
      //   title: 'Transactions',
      //   icon: DollarSign,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/transactions/completed',
      // },

      // {
      //   title: ' App Config',
      //   icon: HelpCircle,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/setting',
      //   children: [
      //     {
      //       title: 'App setting ',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/app-setting',
      //     },

      //     {
      //       title: 'Pages ',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/pages',
      //     },
      //   ],
      // },
      {
        title: 'Settings',
        icon: Settings,
        type: 'sub',
        active: false,
        path: '/dashboard/settings',
        children: [
          {
            title: 'All Settings',
            type: 'link',
            active: false,
            path: '/dashboard/settings',
          },
          {
            title: 'Add Settings',
            type: 'link',
            active: false,
            path: '/dashboard/addSetting',
          },
        ],
      },
      {
        title: 'Reviews',
        icon: Star,
        type: 'link',
        active: false,
        path: '/dashboard/reviews',
      },
      {
        title: 'Webhook Logs',
        icon: FcAcceptDatabase,
        type: 'link',
        active: false,
        path: '/webhook',
      },
      // {
      //   title: 'My Plans',
      //   icon: Award,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/plans',
      // },
      // {
      //   title: 'Support',
      //   icon: HelpCircle,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/support',
      // },
    ],
  },
];
