import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { deleteShopApi, editShopApi,  getStoresApi, postShopApi } from "../../../../api/fetchShop";
import ShowMessage from "../../../../components/Toast/Toast";
import { delay } from "../../../../helper/utility";
import actions from './actions';

function* callFetchStoreReq(action) {

    try {
        let apiResponse = yield call(getStoresApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.GET_SHOP_SUC,
            statusCode: status,
            data: data
        });
    } catch (err) {

        if (err & err?.response) {
            yield put({
                type: actions.GET_SHOP_FAIL,
                payload: err.response.message
            })
        }
        yield put({
            type: actions.GET_SHOP_FAIL,
            payload: err.message
        });
    }
}



function* callSendShopReq(action) {

    try {
        let apiResponse = yield call(postShopApi, action.payload);

        let { status } = apiResponse;

        yield put({
            type: actions.SEND_SHOP_SUC,
            statusCode: status,
        });
        delay(1000);
        yield ShowMessage(status, 'Shop added successfully');

    } catch (err) {
        if (err & err?.response) {
            yield put({
                type: actions.SEND_SHOP_FAIL,
                payload: err.response.message
            })
        }
        yield put({
            type: actions.SEND_SHOP_FAIL,
            payload: err.message
        })
    }


}
function* callDeleteShopReq(action) {
    try {
        let apiResponse = yield call(deleteShopApi, action.payload);

        let { status } = apiResponse;

        yield put({
            type: actions.DLT_SHOP_SUC,
            statusCode: status,
            id: action.payload
        });
        delay(1000);
        yield ShowMessage(status, 'Shop deleted successfully.');

    } catch (err) {
        if (err && err?.response) {
            yield put({
                type: actions.DLT_SHOP_FAIL,
                payload: err.response.message
            })
        }
        yield put({
            type: actions.DLT_SHOP_FAIL,
            payload: err.message
        });
    }
}
function* callEditShopReq(action) {
    try {
        let apiResponse = yield call(editShopApi, action.id, action.data);

        let { status } = apiResponse;
        const message = 'Shop edited successfully.';
        yield put({
            type: actions.EDIT_SHOP_SUC,
            statusCode: status,
            message
        });
        delay(1000);
        yield ShowMessage(status, message);

    } catch (err) {
        if (err && err?.response) {
            yield put({
                type: actions.EDIT_SHOP_FAIL,
                payload: err.response.message
            })
        }
        yield put({
            type: actions.EDIT_SHOP_FAIL,
            payload: err.message
        });
    }
}

export function* fetchStore() {

    yield takeEvery(actions.GET_SHOP_REQ, callFetchStoreReq);

}

export function* sendShop() {
    yield takeEvery(actions.SEND_SHOP_REQ, callSendShopReq);
}
export function* deleteShop() {

    yield takeEvery(actions.DLT_SHOP_REQ, callDeleteShopReq);

}

export function* editShop() {
    yield takeEvery(actions.EDIT_SHOP_REQ, callEditShopReq);
}



export default function* () {
    return yield all([
        fork(fetchStore),
        fork(sendShop),
        fork(deleteShop),
        fork(editShop),
    ])
}
