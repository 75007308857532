import axios from '../axios';

export const postCouponApi = (options) => {
  return axios.post('/coupons', options);
};

export const getCouponApi = ({ id = '', mode = '', search = '' }) => {
  const url = ['/coupons?filter[order]=created_on%20DESC'];
  if (id) {
    return axios.get(`/coupons/${id}`);
  } else {
    if (search) {
      let regExp = new RegExp(search, 'gi');
      url.push(`filter[where][code][regexp]=${regExp}`);
    }
    let finalUrl = url.join('&');
    return axios.get(finalUrl);
  }
};
export const deleteCouponApi = (id) => {
  return axios.delete(`/coupons/${id}`);
};

export const editCouponApi = (id, data) => {
  return axios.patch(`/coupons/${id}`, data);
};
