const apiBaseUrl = "https://api.salesberry.com.np"
// export const apiBaseUrl = 'http://localhost:5090';

const prod = {
  LOGO: '/Salesberry-Logo.svg',
  LOGO_DARK: '/Salesberry-Logo.svg',
  APP_NAME: 'SalesBerry',
  MAIL: 'webadmin@salesberry.com',
  LOCATION2: 'LALITPUR BAGMATI',
  COUNTRY: 'NEPAL',
  LOCATION1: 'SATDOBATO',
  API_BASE_URL: apiBaseUrl,
  FILE_URL: 'https://sb-assests.s3.ap-southeast-1.amazonaws.com/',
  AMAZON_URL: 'https://ecommerce-supreme.s3.ap-south-1.amazonaws.com',
  appName: 'SalesBerry',
  baseURL: 'https://apidev.salesberry.com.np',
  assetURL: 'https://sb-assests.s3.ap-southeast-1.amazonaws.com/',
  httpAssetURL: 'https://a.classified.doransoft.com/asset/upload/image/',
  WEBSITE: 'salesberry.com',
};

// export const APP_CONFIG = process.env.NODE_ENV === 'development' ? dev : prod;
export const APP_CONFIG = prod;
