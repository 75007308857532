import axios from '../axios';

export const getWebhookLogsApi = ({ page, pageSize, webhookType, search }) => {
  let query = [];
  if (page) query.push(`filter[limit]=${pageSize}`);
  if (pageSize) query.push(`filter[skip]=${pageSize * (page - 1)}`);
  // if (search) {
  //   let searchQuery = new RegExp(search, 'gi');
  //   query.push(`filter[where][name][regexp]=${searchQuery}`);
  // }
  if (webhookType) query.push(`filter[where][webHookType]=${webhookType}`);
  query.push(`filter[order]=created_on%20DESC`);
  return axios.get(`/webhook/logs?${query.join('&')}`);
};

export const deleteWebhookApi = (id) => {
  return axios.delete(`/webhook/${id}`);
};

export const getWebhookCount = () => {
  return axios.get('/webhooks/count');
};
