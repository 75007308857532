import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from '../redux/index';
import rootSagas from '../sagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

console.log(process.env.NODE_ENV);

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(sagaMiddleware, routerMiddleware(history)),
    // process.env.NODE_ENV === "development"  &&
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
);
sagaMiddleware.run(rootSagas);

export default store;
