import axios from '../axios';

const checkCode = (status) => {
  switch (status) {
    case 'confirmed':
      return 'OD_CREATED';
    case 'rejected':
      return 'OD_CANCELLED';
    default:
      return 'OD_STATUS';
  }
};

export const fetchOrderApi = ({
  page,
  perPage,
  selectedStatus,
  codeFilter,
  deliveryStatus,
}) => {
  let query = [];
  if (page) query.push(`filter[limit]=${perPage}`);
  if (perPage) query.push(`filter[skip]=${perPage * (page - 1)}`);
  if (selectedStatus && selectedStatus != 'all')
    query.push(`filter[where][status]=${selectedStatus}`);
  if (codeFilter) query.push(`filter[where][id]=${codeFilter}`);
  if (deliveryStatus) query.push(`filter[where][status]=${deliveryStatus}`);
  query.push(`filter[order]=created_on%20DESC`);
  return axios.get(`/orders?${query.join('&')}`);
};

export const editOrderApi = (status, id, text) => {
  let object = {
    order_code: checkCode(status),
    status: status,
    remarks: text ? text : `Order has been ${status}`,
  };
  return axios.patch(`/orders/changeStatus/?id=${id}`, object);
};

export const orderCountApi = () => {
  return axios.get('orders/count');
};

export const PaymentStatusChangeApi = (id, body) => {
  return axios.patch(`/transaction-history/${id}`, body);
};

export const ordersDeliveredCountApi = () =>
  axios.get(`/orders/count?[where][status]=delivered`);

export const ordersPendingCountApi = () =>
  axios.get(`/orders/count?[where][status]=received`);

export const ordersActiveCountApi = () =>
  axios.get(`/orders/count?[where][status]=confirmed`);

export const ordersCancelCountApi = () =>
  axios.get(`/orders/count?[where][status]=rejected`);

export const ordersProcessingCountApi = () =>
  axios.get(`/orders/count?[where][status]=processing`);

export const ordersPaidCountApi = () =>
  axios.get(`/orders/count?[where][status]=paid`);

export const ordersOutforDeliveryCountApi = () =>
  axios.get(`/orders/count?[where][status]=outForDelivery`);
