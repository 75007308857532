import axios from "../axios";

export const getVendorApi = (options) => {
    return axios.get('/vendors', options);
}

export const patchVendorStore = (body) => {
    return axios.patch('/assign/vendor/tostore', body);
}



export const postVendorApi = (options) => {
    return axios.post('/admin/register/vendor', options)
}

export const deleteVendorApi = (id) => {
    return axios.delete(`/users/${id}`);
}
export const editVendorApi = (id, data) => {
    return axios.patch(`/users/${id}`, data);
}