// import { call, put, takeLatest } from '@redux-saga/core/effects';
// import { addUserApi, getUserApi, getUserProfile } from '../../../api/user';
// import { getRolesApi } from '../../../api/roles';
// import actions from './action';
// import ShowMessage from '../../../components/Toast/Toast';

// function transformData(userDetails) {
//   userDetails.roleId = parseInt(userDetails.roleId);
// }

// function* getRolesWorker() {
//   try {
//     const { data, status } = yield call(getRolesApi);
//     yield put({
//       type: actions.GET_ROLES_SUC,
//       payload: data,
//       status,
//     });
//   } catch (error) {
//     if (error && error.response) {
//       yield put({
//         type: actions.GET_ROLES_FAIL,
//         message: error.response.data.error.message,
//         status: error.status,
//       });
//     }
//   }
// }

// function* addUserWorker(userDetails) {
//   try {
//     transformData(userDetails.payload);

//     const { data, status } = yield call(addUserApi, userDetails.payload);
//     yield put({
//       type: actions.ADD_USER_SUC,
//       payload: data,
//       status,
//     });
//     yield ShowMessage(status, 'Add User Succesfull');
//   } catch (error) {
//     if (error && error.response) {
//       const status = error?.response?.data?.error?.statusCode || 502;
//       yield ShowMessage(status, error.response.data.error.message);
//     }
//   }
// }

// function* getUserWorker() {
//   try {
//     const { data, status } = yield call(getUserApi);
//     yield put({
//       type: actions.GET_USER_SUC,
//       payload: data,
//       status,
//     });
//   } catch (error) {
//     if (error && error.response) {
//       const status = error?.response?.data?.error?.statusCode || 502;
//       yield ShowMessage(status, error.response.data.error.message);
//     }
//   }
// }

// function* getCurrentUsr() {
//   try {
//     const { data, status } = yield call(getUserProfile);
//     yield put({
//       type: actions.GET_USER_SUC,
//       payload: data,
//       status,
//     });
//   } catch (error) {
//     if (error && error.response) {
//       const status = error?.response?.data?.error?.statusCode || 502;
//       yield ShowMessage(status, error.response.data.error.message);
//     }
//   }
// }

// export function* getRolesWatcher() {
//   yield takeLatest(actions.GET_ROLES_REQ, getRolesWorker);
// }

// export function* getUserWatcher() {
//   yield takeLatest(actions.GET_USER_REQ, getUserWorker);
// }
// export function* getCurrentUser() {
//   yield takeLatest(actions.GET_USER_REQ, getCurrentUsr);
// }

import { call, put, takeLatest, all, fork, delay } from 'redux-saga/effects';
import {
  getUserProfile,
  updateProfilePic,
  uploadProfilePic,
  getUserProfilePic,
  uploadCoverPicApi,
  getUserCoverPicApi,
  updateProfileApi,
  updateCoverPicfirstApi,
  updateCoverPiclastApi,
} from '../../../../api/fetchUser';
import { sendFile } from '../../../../api/axios'
import actions from './actions';
import { message } from 'antd';

function* callEditProfileReq(action) {
  try {
    let apiResponse = yield call(updateProfileApi, action.payload);
    let { status } = apiResponse;
    message.success("Update successful");
    if (status===200 || status === 204) {
      let response = yield call(getUserProfile);
      let { data, status1 } = response;
      yield put({
        type: actions.GET_PROFILE_SUC,
        statusCode: status1,
        profiles: data,
      })
    }
    else{
      yield put({
        type: actions.GET_PROFILE_FAIL,
        payload: "Update Failed",
      });
    }
  } catch (err) {

  }
}

function* callFetchProfileReq(action, id) {
  try {
    let apiResponse = yield call(getUserProfile, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: actions.GET_PROFILE_SUC,
      statusCode: status,
      profiles: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_PROFILE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_PROFILE_FAIL,
      payload: err.message,
    });
  }
}

function* callEditProfilePic(action) {
  let { imgurl, imgid, name } = action?.payload;
  try {
    let apiResponse = yield call(updateProfilePic, imgurl, imgid, name);

    let { data, status } = apiResponse;
    yield put({
      type: actions.EDIT_PROFILEPIC_SUC,
      payload: imgurl,
      statusCode: status,
    });
    message.success('Profile Image Edited Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Couldnot update your Profile Image');
  }
}

function* callEditCoverPic(action) {
  try {
    let { imgurl, imgid, bucketData } = action?.payload;
    let apiResponse = yield call(updateCoverPicfirstApi, imgurl);
    let { data } = apiResponse;
    delay(2000)
    if (data) {
      let apiResponse2 = yield call(updateCoverPiclastApi, data?.id, bucketData)
      let { status } = apiResponse2
      if (status >= 200 && status <= 204) {
        yield put({
          type: actions.EDIT_COVERPIC_SUC,
          payload: data[0]?.url || "",
        });
        message.success('Cover Image Edited Successfully');
      }
      else {
        message.error('Could not update your Cover Image');
        yield put({
          type: actions.EDIT_COVERPIC_FAIL,
          payload: 'Could not update your Cover Image',
        });
      }
    }

  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_COVERPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_COVERPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not update your Cover Image');
  }
}

function* callFetchProfilePic(action) {
  try {
    let apiResponse = yield call(getUserProfilePic, action);
    let { data, status } = apiResponse;
    let image = data[data.length - 1]; // it recieves in array so we are taking last image

    yield put({
      type: actions.FETCH_PROFILEPIC_SUC,
      payload: image?.url,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.FETCH_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.FETCH_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not get your Profile Image');
  }
}

function* callFetchCoverPic(action) {
  try {
    let apiResponse = yield call(getUserCoverPicApi, action);
    let { data, status } = apiResponse;
    let image = data[data.length - 1];
    yield put({
      type: actions.FETCH_COVERPIC_SUC,
      payload: image?.url,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.FETCH_COVERPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.FETCH_COVERPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not get your Profile Image');
  }
}

function* callSendFilePic(action) {
  //it gets {file,type}  as params
  try {
    let apiResponse = yield call(sendFile, action.payload?.file);
    let { data, status } = apiResponse;
    let imgurl = data[0]?.url; //taking img url
    let imgid = data[0]?.id; // taking img id
    let bucketData = data[0]?.data; // taking bucket data
    yield put({
      type: actions.UPLOAD_FILE_PROFILEPIC_SUC,
      payload: { imgurl, imgid, bucketData },
    });
    // delay(5000);
    if (action.payload?.type === actions.UPLOAD) {
      //this will run when we upload pic for the first time
      yield put({
        type: actions.UPLOAD_PROFILEPIC_REQ,
        payload: imgurl,
      });
    }
    if (action?.payload?.type === actions.EDIT) {
      //this will run when we edit profile pic
      yield put({
        type: actions.EDIT_PROFILEPIC_REQ,
        payload: { imgurl, imgid, name: action.payload.name }, //passing imgURl and imgid to callEditProfilePic generator fucntion

      });
    }
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* callSendCoverFile(action) {
  try {
    let apiResponse = yield call(sendFile, action.payload?.file);
    let { data, status } = apiResponse;
    let imgurl = data[0]?.url; //taking img url
    let imgid = data[0]?.id; // taking img id
    let bucketData = data[0]?.data; // taking bucket data

    yield put({
      type: actions.UPLOAD_FILE_COVERPIC_SUC,
      payload: { imgurl, imgid, bucketData },
    });
    delay(3000);
    if (action?.payload?.type === actions.UPLOAD) {
      yield put({
        type: actions.UPLOAD_COVERIMG_REQ,
        payload: imgurl,
      });
    }
    if (action?.payload?.type === actions.EDIT) {
      //this will run when we edit covrer pic
      yield put({
        type: actions.EDIT_COVERPIC_REQ,
        payload: { imgurl, imgid, bucketData }, //passing imgURl and imgid to callEditProfilePic generator fucntion
      });
    }
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_FILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_FILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* callUploadCoverPic(action) {
  try {
    let apiResponse = yield call(uploadCoverPicApi, action.payload);

    yield put({
      type: actions.UPLOAD_COVERIMG_SUC,
      payload: apiResponse?.data?.url,
    });
    message.success('Cover Image Added Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Cover Image is not uploaded');
  }
}

export function* callUploadProfilePic(action) {
  try {
    let apiResponse = yield call(uploadProfilePic, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.UPLOAD_PROFILEPIC_SUC,
      payload: { data, status },
    });
    message.success('Profile Image Added Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* fetchProfile() {
  yield takeLatest(actions.GET_PROFILE_REQ, callFetchProfileReq);
}

export function* editProfile() {
  yield takeLatest(actions.EDIT_PROFILE_REQ, callEditProfileReq);
}

export function* editProfilePicture() {
  yield takeLatest(actions.EDIT_PROFILEPIC_REQ, callEditProfilePic);
}

export function* editCoverPicture() {
  //editing cover picture
  yield takeLatest(actions.EDIT_COVERPIC_REQ, callEditCoverPic);
}

export function* fetchProfilePic() {
  yield takeLatest(actions.FETCH_PROFILEPIC_REQ, callFetchProfilePic);
}

export function* fetchCoverPic() {
  yield takeLatest(actions.FETCH_COVERPIC_REQ, callFetchCoverPic);
}

export function* uploadProfilePicture() {
  yield takeLatest(actions.UPLOAD_PROFILEPIC_REQ, callUploadProfilePic);
}

export function* uploadFilePic() {
  //profile pictre
  yield takeLatest(actions.UPLOAD_FILE_PROFILEPIC_REQ, callSendFilePic);
}

export function* uploadCoverFilePic() {
  // cover image
  yield takeLatest(actions.UPLOAD_FILE_COVERPIC_REQ, callSendCoverFile);
}

export function* uploadCoverPic() {
  yield takeLatest(actions.UPLOAD_COVERIMG_REQ, callUploadCoverPic);
}

export default function* () {
  return yield all([
    fork(fetchProfile),
    fork(editProfile),
    fork(editCoverPicture),
    fork(editProfilePicture),
    fork(fetchProfilePic),
    fork(fetchCoverPic),
    fork(uploadProfilePicture),
    fork(uploadFilePic),
    fork(uploadCoverFilePic),
    fork(uploadCoverPic)
  ])
}
