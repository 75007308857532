import Axios from './axios';
import { clearToken, getBearerToken } from '../helper/utility';
import { APP_CONFIG } from '../app/config';

export const fetchProductsApi = () => {
  return Axios.get(`/products`);
};

export const fetchCategoriesApi = () => {
  return Axios.get(`/categories`);
};

export const fetchBrandsApi = () => {
  return Axios.get(`/brands`);
};

export const addProductApi = (options) => {
  return Axios.post('/products', options);
};

export const getProductApi = async (filter) => {
  const {
    productPerPage,
    page,
    brand,
    category,
    isActive,
    lowStock,
    name,
    date,
    noVaraint,
    skip,
    isVerified,
    noImages,
    isPublish,
    brandId,
    categoryId,
    productId,
  } = filter;
  let filterArr = [];
  const currentPage = page || 1;
  const limit = productPerPage || 10;
  let storeId = 1;
  if (brandId) filterArr.push(`filter[where][brand_id]=${brandId}`);
  if (categoryId) filterArr.push(`filter[where][category_id]=${categoryId}`);

  if (productPerPage) filterArr.push(`filter[limit]=${limit}`);
  if (skip) filterArr.push(`filter[skip]=${skip}`);
  if (brand && brand !== 'all')
    filterArr.push(`filter[where][brands][name]=${brand}`);
  if (productId) filterArr.push(`filter[where][productId][regexp]=/${productId}/gi`);
  // if (brandId) filterArr.push(`filter[where][brand_id]=${brandId}`);
  // if (categoryId) filterArr.push(`filter[where][category_id]=${categoryId}`);

  if (category && category !== 'all')
    filterArr.push(`filter[where][category]=${category}`);
  if (isActive) filterArr.push(`filter[where][isActive]=${isActive}`);
  if (isPublish) filterArr.push(`filter[where][published]=${isPublish}`);
  if (lowStock) filterArr.push(`filter[where][stock][lte]=${lowStock}`);
  if (name) {
    let searchQuery = new RegExp(name, 'gi');
    filterArr.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  if ((isVerified === true || isVerified === false) && isVerified !== 'all')
    filterArr.push(`filter[where][isVerified]=${isVerified}`);
  if (date)
    filterArr.push(
      `filter[where][createdOn][gte]=${date.startDate}&filter[where][createdOn][lte]=${date.endDate}`,
    );

  let response = await Axios.get(`/products?${filterArr.join('&')}`);

  // let updatedData = response?.data?.products && response?.data?.products?.length && response?.data?.products?.filter((item) => {
  //   if (item?.images ? !item?.images?.length > 0 : true) {
  //     return true
  //   }
  // })
  // console.log(updatedData.length);
  // response.data.products = updatedData
  // return response
  if (noVaraint || noImages) {
    let updatedData;
    if (noVaraint) {
      updatedData =
        response?.data?.products &&
        response?.data?.products?.length &&
        response?.data?.products?.filter((item) => {
          if (item.variants && item.variants.length === 1) {
            return true;
          }
        });
    }
    if (noImages) {
      updatedData =
        response?.data?.products &&
        response?.data?.products?.length &&
        response?.data?.products?.filter((item) => {
          if (item?.images ? !item?.images?.length > 0 : true) {
            return true;
          }
        });
    }
    response.data.products = updatedData;
    return response;
  } else {
    return response;
  }

  // return Axios.get(`/vendor/products`);
};
export const fetchSearchQueryApi = (payload) => {
  return Axios.get(`/product/search/?q=${payload}`);
};

export const getProductCountApi = () => {
  return Axios.get('/products/count');
};

export const editProductApi = (id, data) => {
  return Axios.patch(`/products/${id}`, data);
};
export const deleteProductApi = (id) => {
  return Axios.delete(`/products/${id}`);
};

export const getProductByIdApi = (id) => {
  return Axios.get(`/products/${id}`);
};

export const uploadImagesApi = (id, data) => {
  return Axios.post(`/product/${id}/image-upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const fetchProductImagesApi = (id) => {
  return Axios.get(`/product/${id}/images`);
};

export const deleteProductImageApi = (id) => {
  return Axios.delete(`/product-images/${id}`);
};

export const getVariantsApi = (id) => {
  return Axios.get(`categories/${id}/variants`);
};

export const fetchProductVariantApi = (id) => {
  return Axios.get(`/variants/${id}/category-variants`);
};

export const editProductVerifiedStatusApi = (data) => {
  const { slug, ...rest } = data;

  return Axios.patch(`/products/verify/${slug}`, {
    ...rest,
  });
};

export const editProductPublishedApi = (data, slug) => {
  return Axios.patch(`/products/publish/${slug}`, {
    published: data,
  });
};

export const addProductDealsApi = (id, payload) => {
  const apiUrl = APP_CONFIG.API_BASE_URL + `/deals/${id}/product-deals`;
  const accessToken = getBearerToken();
  return fetch(apiUrl, {
    method: 'POST',
    headers: !accessToken ? headers : authHeaders(accessToken),
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error('Server Error: ' + err.toString());
    });
};

const headers = {
  'Content-type': 'application/json',
  Accept: '*/*',
  'access-control-allow-methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
  'Access-Control-Allow-Origin': '*',
};

const authHeaders = (accessToken) => ({
  ...headers,
  Authorization: accessToken,
});
