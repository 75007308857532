// import './wdyr'; // Why did you render for optimizations
import { ConnectedRouter } from 'connected-react-router';
import React, { Fragment, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  BrowserRouter,
} from 'react-router-dom';
import Signin from './auth/signin';
import './index.scss';
import AppLayout from './layout/AppLayout';
import actions from './pages/authentication/redux/actions';
import Register from './pages/authentication/register';
import Error404 from './pages/errors/error404';
import { routes } from './route';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { history } from './store/index';
import PrivateRoute from './utils/PrivateRoute';
import ProtectedRoute from './utils/ProtectedRoute';
import 'antd/dist/antd.css';
import PageLoader from './components/PageLoader/PageLoader';
import Icon from 'assets/images/Salesberry-Logo.svg';
import 'sweetalert2/src/sweetalert2.scss';
import { APP_CONFIG } from 'app/config';

const Root = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // here
  const auth = useSelector((state) => state.Auth);

  const preLocationStatus = useSelector((state) => state.router.location);

  useEffect(() => {
    // const animation =
    //   localStorage.getItem('animation') ||
    //   ConfigDB.data.router_animation ||
    //   'fade';

    // setAnim(animation);
    // if(localStorage.getItem('access_token')){
    dispatch(actions.getTokenFromStorage());
    // }
  }, []);
  return (
    <BrowserRouter>
      {/* <Loader show={auth.fetchingCurrentUser} /> */}
      <Suspense fallback={<PageLoader Logo={Icon} logoAlt={APP_CONFIG.APP_NAME} />}>
        <Switch>
          <ProtectedRoute
            isAuthenticated={auth.isLoggedIn}
            path={'/login'}
            // redirectTo={preLocationStatus?.state?.from?.pathname}
            redirectTo={preLocationStatus?.pathname}
            component={Signin}
          />
          <ProtectedRoute
            isAuthenticated={auth.isLoggedIn}
            path={'/signup'}
            component={Register}
          />
          <Route
            path="/"
            exact
            render={() => <Redirect to={location?.state?.from || '/login'} />}
          />
          {routes.map(({ path, exact, Component }, index) => (
            <PrivateRoute
              key={`router-${path}-${index}`}
              path={path}
              exact={exact || false}
              layout={AppLayout}
              component={Component}
              redirectTo={preLocationStatus?.pathname}
              isAuthenticated={auth.isLoggedIn}
            />
          ))}
          {/* <Route component={Error404} /> */}
          <Route path={'*'} component={Error404}></Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
