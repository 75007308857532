const action = {
  GET_PROFILE_REQ: 'GET_PROFILE_REQ',
  GET_PROFILE_SUC: 'GET_PROFILE_SUC',
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

  GET_ROLES_REQ: `GET_ROLES_REQ`,
  GET_ROLES_SUC: ` GET_ROLES_SUC`,
  GET_ROLES_FAIL: `GET_ROLES_FAIL`,

  EDIT_PROFILE_REQ: 'EDIT_PROFILE_REQ',
  EDIT_PROFILE_SUC: 'EDIT_PROFILE_SUC',
  EDIT_PROFILE_FAIL: 'EDIT_PROFILE_FAIL',

  EDIT_PROFILEPIC_REQ: "EDIT_PROFILEPIC_REQ",
  EDIT_PROFILEPIC_SUC: "EDIT_PROFILEPIC_SUC",
  EDIT_PROFILEPIC_FAIL: "EDIT_PROFILEPIC_FAIL",

  EDIT_COVERPIC_REQ: "EDIT_COVERPIC_REQ",
  EDIT_COVERPIC_SUC: "EDIT_COVERPIC_SUC",
  EDIT_COVERPIC_FAIL: "EDIT_COVERPIC_FAIL",


  UPLOAD_FILE_PROFILEPIC_SUC: "UPLOAD_FILE_PROFILEPIC_SUC",
  UPLOAD_FILE_PROFILEPIC_REQ: "UPLOAD_FILE_PROFILEPIC_REQ",
  UPLOAD_FILE_PROFILEPIC_FAIL: "UPLOAD_FILE_PROFILEPIC_FAIL",

  UPLOAD_FILE_COVERPIC_SUC: "UPLOAD_FILE_COVERPIC_SUC",
  UPLOAD_FILE_COVERPIC_REQ: "UPLOAD_FILE_COVERPIC_REQ",
  UPLOAD_FILE_COVERPIC_FAIL: "UPLOAD_FILE_COVERPIC_FAIL",

  UPLOAD_PROFILEPIC_REQ: "UPLOAD_PROFILEPIC_REQ",
  UPLOAD_PROFILEPIC_SUC: "UPLOAD_PROFILEPIC_SUC",
  UPLOAD_PROFILEPIC_FAIL: "UPLOAD_PROFILEPIC_FAIL",

  FETCH_PROFILEPIC_REQ: "FETCH_PROFILEPIC_REQ",
  FETCH_PROFILEPIC_SUC: "FETCH_PROFILEPIC_SUC",
  FETCH_PROFILEPIC_FAIL: "FETCH_PROFILEPIC_FAIL",

  FETCH_COVERPIC_REQ: "FETCH_COVERPIC_REQ",
  FETCH_COVERPIC_SUC: "FETCH_COVERPIC_SUC",
  FETCH_COVERPIC_FAIL: "FETCH_COVERPIC_FAIL",

  UPLOAD_COVERIMG_REQ: "UPLOAD_COVERIMG_REQ",
  UPLOAD_COVERIMG_SUC: "UPLOAD_COVERIMG_SUC",
  UPLOAD_COVERIMG_FAIL: "UPLOAD_COVERIMG_FAIL",

  //type for file edit or upload
  EDIT: "EDIT",
  UPLOAD: "UPLOAD",

  fetchProfileReq: (payload) => ({
    type: action.GET_PROFILE_REQ,
    payload,
  }),

  getRole: () => ({
    type: action.GET_ROLES_REQ,
  }),

  editProfile: (payload) => ({
    type: action.EDIT_PROFILE_REQ,
    payload
  }),


  fetchCoverPicReq: (payload) => ({
    type: action.FETCH_COVERPIC_REQ,
    payload,
  }),

  editProfilePicReq: (payload) => ({
    type: action.EDIT_PROFILEPIC_REQ,
    payload
  }),

  uploadFileProfileReq: (payload) => ({ //profile piture file 
    type: action.UPLOAD_FILE_PROFILEPIC_REQ,
    payload
  }),

  uploadFileCoverReq: (payload) => ({ //cover picture file of profile
    type: action.UPLOAD_FILE_COVERPIC_REQ,
    payload
  }),

  uploadProfilePicReq: (payload) => ({
    type: action.UPLOAD_PROFILEPIC_REQ,
    payload
  }),

  fetchProfilePicReq: () => ({
    type: action.FETCH_PROFILEPIC_REQ,
  }),

  fetchCoverPicReq: () => ({
    type: action.FETCH_COVERPIC_REQ
  }),

  uploadCoverPicReq: (payload) => ({
    type: action.UPLOAD_COVERIMG_REQ,
    payload
  })

};

export default action;