import axios from 'axios';
import Axios from '../axios';

export const getUserApi = (body) => {
  if (typeof body !== 'object') {
    return Axios.get(`/users/${body}`);
  } else {
    let { perPage, page, search,status } = body;
    let array = [];

    if (search) {
      let searchQuery = new RegExp(search, 'gi');
      array.push(`filter[where][or][0][firstName][regexp]=${searchQuery}&filter[where][or][1][lastName][regexp]=${searchQuery}&filter[where][or][2][email][regexp]=${searchQuery}`);
    }
    if(status !== ""){
      console.log(status)
      array.push(`filter[where][isActive]=${status}`);

    }
    if (page) array.push(`filter[limit]=${perPage}`);
    if (perPage) array.push(`filter[skip]=${+(page - 1) * +perPage}`);
    return Axios.get(`/customers?${array.join('&')}&filter[order]=created_on DESC`);
  }
};
export const addUserApi = (userDetails) => {
  return Axios.post('/register/roled-user', userDetails);
};

export const getUserProfile = (options) => {
  return Axios.get('/users/me', options);
};

export const getUserProfilePic = (options) => {
  return Axios.get(`/profile-image`, options);
};

export const getUserCoverPicApi = (options) => {
  return Axios.get(`/cover-image`, options);
};

export const updateProfileApi = (options) => {
  return Axios.patch('/profile/update', options);
};

// export const updateProfilePic = (url) => {
//   return Axios.patch('/profile/update', { avatar: url });
// }; //TODO PROFILE IMAGE UPDATE

export const updateProfilePic = (url, id, name) => {
  // return Axios.patch('/profile/update', { avatar: url });
  return Axios.patch('/shops', { avatar: url, name: name });
};

export const uploadProfilePic = (image) => {
  return Axios.post(`/profile-image`, { url: image });
};

export const uploadCoverPicApi = (image) => {
  return Axios.post(`/cover-image`, { url: image });
};

// export const updateCoverPicApi =(url) =>{
//   return Axios.patch('/profile/update', {"coverImage":[url]});
// }

export const updateCoverPicfirstApi = (url) => {
  return Axios.post(`/cover-image`, { url: url });
};

export const updateCoverPiclastApi = (imgID, bucketData) => {
  let object = {
    key: bucketData.Key,
    bucket: bucketData.Bucket,
    url: bucketData.Location,
  };
  return Axios.put(`/cover-image/${imgID}`, object);
};

export const provincesApi = () => {
  return Axios.get(`/provinces`);
};

export const districtsApi = (province) => {
  return Axios.get(`/provinces/${province}/districts`);
};

export const municipalitiesApi = (district) => {
  return Axios.get(`/districts/${district}/municipalities`);
};

export const wardsApi = (municipality) => {
  return Axios.get(`/municipalities/${municipality}/wards`);
};

export const getAddress = (id) => {
  return Axios.get(`/wards/${id}`);
};
export const fetchUser = (options) => {
  return axios.get('/users', options);
};

export const changeStatusApi = (id, body) => {
  return Axios.patch(`/users/${id}/changeStatus`, body);
};

export const fetchUserByName = (name) => {
  let searchQuery = new RegExp(name, 'gi');
  return Axios.get(
    `/users/?filter[where][firstName][regexp]=${searchQuery}&&filter[limit]=8`,
  );
};
