const paths = {
  Dashboard: '/dashboard',

  Deals: '/dashboard/deals',
  AddDeal: '/dashboard/deal/create',
  EditDeal: '/dashboard/deal/:id/edit',

  Categories: '/dashboard/categories',
  createCategories: '/dashboard/category/create',
  multipleCategories: '/dashboard/category/multiple',
  editCategory: '/dashboard/category/:id/edit',
  addCategoryImage: '/category/:id/upload',

  Brands: '/dashboard/brands',
  createBrand: '/dashboard/brand/create',
  editBrand: '/dashboard/brand/edit/:id',
  addBrandImage: '/brand/:id/upload',

  assetManager: '/asset-manager/upload-image',

  addBanner: '/banners/add',
  allBanner: '/banners/all',
  editBanner: '/banners/:id',

  allProduct: '/product',
  editProduct: '/product/:id/edit',

  uploadImage: '/asset-manager/upload-image',

  webhook: '/webhook',
};

export default paths;
