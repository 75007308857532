import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { APP_CONFIG } from 'app/config';

const Footer = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">{`Copyright ${new Date().getFullYear()} © ${APP_CONFIG.APP_NAME}.`}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
