import axios from "../axios";

export const postBrandApi = (options) => {
    return axios.post('/brands', options);
}
export const getBrandsApi = () => {
    return axios.get('/brands')
}

export const getBrandApi = ({ page, perpage, status,search }) => {
    let query = [];
    if (page) query.push(`filter[limit]=${perpage}`);
    if (perpage) query.push(`filter[skip]=${perpage * (page - 1)}`);
    if(search){
        let searchQuery = new RegExp(search,'gi');
        query.push(`filter[where][name][regexp]=${searchQuery}`)
    }
    if (status) query.push(`filter[where][isActive]=${status}`);
    return axios.get(`/brands?${query.join('&')}`);
}
export const deleteBrandApi = (id) => {
    return axios.delete(`/brands/${id}`);
}

export const editBrandApi = (id, data) => {
    return axios.patch(`/brands/${id}`, data);
}
export const getBrandCount = () => {
    return axios.get('/brands/count');
}



